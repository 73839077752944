<template>
  <div>
    <date-picker
      class="hijri-date"
      calendar="hijri"
      :locale="'ar-sa'"
      :value="value"
      format="YYYY-MM-DD"
      input-format="YYYY-MM-DD"
      display-format="iYYYY/iMM/iDD"
      :editable="true"
      :clearable="true"
      transition="scale-transition"
      offset-y
      min-width="auto"
      @change="handleChange"
    />
  </div>
</template>
<script>
  // import { format } from 'date-fns/esm'
  import { uuid } from 'vue-uuid'
  // import moment from 'moment-hijri'
  import VueDatetimeJs from 'vue-datetime-js'

  export default {
    name: 'HijriDate',
    components: {
      datePicker: VueDatetimeJs,
    },
    props: {
      value: {
        type: [Date, String],
        default: '',
      },
    },
    data: () => ({
      hejriCallender: false,
      id: '',
      innerDate: '',
      focus: false,
    }),
    // computed: {
    //   computedDate () {
    //     const copmputed = new Date(this.value).toLocaleDateString('ar-sa')
    //     return copmputed.includes('Invalid') ? '' : copmputed
    //   },
    // },
    mounted () {
      this.id = uuid.v4()
    },
    methods: {
      handleClear () {
        this.$emit('input', '')
      },
      // handleInput (value) {
      //   try {
      //     const m = moment(value, 'iYYYY/iM/iD')
      //     this.$emit('input', format(new Date(m.format('YYYY/M/D')), 'yyyy-MM-dd'))
      //   } catch (err) {

      //   }
      // },
      handleChange (value) {
        this.$emit('input', value)

        // console.log(m)
      },
    },
  }
</script>
<style>
.hijri-date.v-card.v-picker--date {
  margin-top: 0px !important;
}

.vpd-input-group .vpd-clear-btn {
  line-height: 50px;
}

.vpd-input-group input {
padding: 13px 11px 8px;
line-height: 30px;
width: 100%;
}
</style>
