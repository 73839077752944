// eslint-disable-next-line camelcase
export const operationTypeList = [
    {
        name: 'سوم',
        id: 'سوم',
    },
    {
        name: 'بيع',
        id: 'بيع',
    },

    {
        name: 'حد',
        id: 'حد',
    },
]
